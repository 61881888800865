<div class="d-flex flex-column align-items-center justify-content-center" >
  <div class="text-center">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="mt-2">
    <a class="labelPage" style="color: #F9FBFF; font-size: 25px;">Cargando...</a>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit, OnDestroy {

  itemSubscription: Subscription | undefined;

  items: Menu[] = [];

  constructor(
    private navService: NavService,
  ) { }

  ngOnInit(): void {
    this.itemSubscription = this.navService.items.subscribe((value) => {
      this.items = value;
    });
  }

  ngOnDestroy(): void {
    this.itemSubscription?.unsubscribe();
  }
}

<div class="simple-header">
    <!-- TODO: VIKINGO IMAGE -->
    <div class="simple-header_image d-flex">
        <span
          id="dropdown_icon_simple_header"
          class="material-symbols-outlined me-4"
          aria-hidden="true"
        >
          menu
        </span>
        <img src="../../../../assets/img/png/vikingo.png" class="mobile-logo logo-1" alt="logo">
    </div>

    <!-- HEADER FOR CATALOGUE -->
    <ng-container *ngIf="showCatalogueOption">
      <div class="simple-header_catalogue-buttons">
        <button
          class="btn btn-link p-0"
          [ngClass]="{
            'heading-6-bold active': !catalogueService.configOrAdmin,
            'heading-6 tx-neutral ': catalogueService.configOrAdmin,
          }"
          (click)="catalogueService.configOrAdmin = false"
        >
          Configuración
        </button>
        <button
          class="btn btn-link heading-6 p-0"
          [ngClass]="{
            'heading-6-bold active': catalogueService.configOrAdmin,
            'heading-6 tx-neutral': !catalogueService.configOrAdmin,
          }"
          (click)="catalogueService.configOrAdmin = true"
        >
          Administrador
        </button>
      </div>
    </ng-container>
 
    <div class="simple-header_user-menu">
      <li ngbDropdown class="main-profile-menu nav nav-item nav-link ps-lg-2">
        <a class="new nav-link profile-user d-flex" href="javascript:;" ngbDropdownToggle>
          <img
            alt=""
            class=""
            src="./assets/img/faces/2.jpg"
          >
        </a>
        
        <div ngbDropdownMenu>
          <div class="menu-header-content p-3 border-bottom">
            <div class="d-flex wd-100p">
              <div class="main-img-user"><img alt="" src="./assets/img/faces/2.jpg" class=""></div>
              <div class="ms-3 my-auto">
                <h6 class="tx-15 font-weight-semibold mb-0">Gabriel</h6>
              </div>
            </div>
          </div>
          <a ngbDropdownItem routerLink="/pages/profile"><i class="far fa-user-circle"></i>Profile</a>
          <a ngbDropdownItem routerLink="/mail/mail-settings"><i class="far fa-sun"></i> Settings</a>
          <a ngbDropdownItem ><i class="far fa-arrow-alt-circle-left"></i> Sign Out</a>
        </div>
      </li>
    </div>
</div>


<!-- FLOATING MENU -->
<div id="floating_menu_simple_header" class="floating-menu d-none">
  <div class="menu-header">
    <h4 class="heading-4-bold text-secondary-dark">
      Grupo PDC
    </h4>

    <button
      class="btn btn-link-primary"
      aria-hidden="true"
      >
      Cambiar
    </button>
  </div>
  <div class="menu-content">
    <ngb-accordion class="nav-accordion">
      <ng-container *ngFor="let item of items; let i = index">
        <ngb-panel title="{{ item.headTitle }}" cardClass="">
          <ng-template ngbPanelContent>
            <ng-container
              *ngTemplateOutlet="
                GroupContainer;
                context: {
                  $implicit: item
                }
              "
            >
            </ng-container>
          </ng-template>
        </ngb-panel>
      </ng-container>
    </ngb-accordion>
  </div>
</div>

<ng-template #GroupContainer let-item>
  <ngb-accordion class="nav-accordion-section">
    <ng-container *ngFor="let section of item.children">
      <ngb-panel>
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button header-button">
            <p class="group-item">
              <span class="material-symbols-outlined group-item_icon">{{ section.icon }}</span><span class="tx-body-1 tx-neutral-90 ms-2">{{ section.title }}</span>
            </p>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <ul>
            <li *ngFor="let subSection of section.children">
              <a class="group-item" (click)="moveToLink( subSection.path )">
                <span class="material-symbols-outlined group-item_icon">{{ subSection.icon }}</span>
                <span class="tx-body-1 tx-neutral-90 ms-2">{{ subSection.title }}</span>
              </a>
              <ul>
                <li *ngFor="let subLink of subSection.children">
                  <a class="group-item" (click)="moveToLink( subLink.path )">
                    <span class="tx-body-2 tx-neutral-90">{{ subLink.title }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</ng-template>

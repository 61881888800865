import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { AuthService } from './shared/services/firebase/auth.service';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { PaletteColors } from './shared/data/constants/palette_color';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  PaletteColor = PaletteColors;

  constructor(
    private authService   : AuthService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinnerService.show();
    // fromEvent(window, 'load').subscribe(() => document.querySelector('#glb-loader')?.classList.remove('loaderShow'));
    fromEvent(window, 'load').subscribe(() => this.spinnerService.hide());

    /* Obtención de token de aplicación */
    this.authService.getMasterToken();
  }

}

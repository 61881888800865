
<!-- breadcrumb -->
<div class="breadcrumb-header justify-content-between">
    <div class="left-content">
    <span class="main-content-title mg-b-0 mg-b-lg-1">{{title}}</span>
    </div>
    <div class="justify-content-center mt-2">
        <ol class="breadcrumb">
            <li class="breadcrumb-item tx-15" *ngFor="let item of items"><a href="javascript:;">{{item}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{active_item}}</li>
        </ol>
    </div>
</div>
<!-- /breadcrumb -->
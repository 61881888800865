export enum PaletteColors {
    /* PRIMARY */
    Primary        = '#001E6D',
    Primary_75     = '#425490',
    Primary_50     = '#818EB5',
    Primary_25     = '#BFC6DA',
    Primary_10     = '#E6E8F0',
    Primary_darker = '#001149',
    /* SECONDARY */
    Secondary        = '#51BFE7',
    Secondary_75     = '#8ECEEA',
    Secondary_50     = '#B2DDF1',
    Secondary_25     = '#D7EEF8',
    Secondary_10     = '#EFF8FC',
    Secondary_darker = '#3C8FB2',
    /* NEUTRALS */
    Neutral_text   = '#4A4C58',
    Neutral_90     = '#6A6C78',
    Neutral_80     = '#7E808D',
    Neutral_70     = '#A8AAB8',
    Neutral_60     = '#C6C8D6',
    Neutral_50     = '#E8EAF9',
    Neutral_40     = '#EFF1FE',
    Neutral_30     = '#F4F6FE',
    Neutral_20     = '#F9FBFF',
    Neutral_darker = '#282A35',
    /* SEMANTICS */
    /* SUCCESS */
    Succcess        = '#06A77D',
    Succcess_75     = '#67BA9F',
    Succcess_50     = '#94D0BE',
    Succcess_25     = '#C8E7DE',
    Succcess_10     = '#EAF6F2',
    Succcess_darker = '#058261',
    /* ERROR */
    Error        = '#E83800',
    Error_75     = '#EE6A40',
    Error_50     = '#F39B7F',
    Error_25     = '#F9CDBF',
    Error_10     = '#FDECE6',
    Error_darker = '#B32E04',
    /* WARNING */
    Warning        = '#FED63A',
    Warning_75     = '#FBE07D',
    Warning_50     = '#FBE9A5',
    Warning_25     = '#FEF4D1',
    Warning_10     = '#FEFBED',
    Warning_darker = '#F2C103',
    /* WARNING */
    Info        = '#5E7FCD',
    Info_75     = '#869FD9',
    Info_50     = '#ADBDE4',
    Info_25     = '#D7DEF2',
    Info_10     = '#F0F2FA',
    Info_darker = '#2D4786',
}
 <!---Global-loader-->
 <!-- <div style="height:100vh;" id="glb-loader" class="loaderShow">
  <img src="./assets/img/loader.svg" class="loader-img" alt="loader">
</div> -->
<!--- End Global-loader-->

<ngx-spinner
  size="medium"
  type="square-jelly-box"
  bdColor="rgba( 0, 17, 71, 0.75 )"
  [color]="PaletteColor.Secondary"
  [fullScreen]="true"
>
  <p style="color: #fFFF">Cargando...</p>
</ngx-spinner>
  <router-outlet></router-outlet>
  
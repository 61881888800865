import { Routes } from "@angular/router";
import { SwitcherContentComponent } from "../layout-components/switcher-content/switcher-content.component";

SwitcherContentComponent
export const SwitcherOneRoute: Routes = [
    {
        path: 'switcher-one-route',
        component: SwitcherContentComponent
    }
];



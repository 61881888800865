<div class="simple-header">
    <!-- TODO: VIKINGO IMAGE -->
    <div class="simple-header_image d-flex">
        <span
          class="material-symbols-outlined me-4"
          aria-hidden="true"
          [ngbPopover]="floatingMenuPopover"
          autoClose="outside"
          >
          menu
        </span>
        <img src="../../../../assets/img/png/vikingo.png" class="mobile-logo logo-1" alt="logo">
    </div>
 
    <div class="simple-header_user-menu">
        <li ngbDropdown class="main-profile-menu nav nav-item nav-link ps-lg-2">
            <a class="new nav-link profile-user d-flex" href="javascript:;" ngbDropdownToggle><img alt=""
                src="./assets/img/faces/2.jpg" class=""></a>
            <div ngbDropdownMenu>
              <div class="menu-header-content p-3 border-bottom">
                <div class="d-flex wd-100p">
                  <div class="main-img-user"><img alt="" src="./assets/img/faces/2.jpg" class=""></div>
                  <div class="ms-3 my-auto">
                    <h6 class="tx-15 font-weight-semibold mb-0">Gabriel</h6>
                  </div>
                </div>
              </div>
              <a ngbDropdownItem routerLink="/pages/profile"><i class="far fa-user-circle"></i>Profile</a>
              <a ngbDropdownItem routerLink="/mail/mail-settings"><i class="far fa-sun"></i> Settings</a>
              <a ngbDropdownItem ><i class="far fa-arrow-alt-circle-left"></i> Sign Out</a>
            </div>
          </li>
    </div>
    <div style="margin-left: -4rem;">
        Gabriel
    </div>
</div>

<ng-template #floatingMenuPopover>
  <app-floating-menu #floatingMenu [items]="items"></app-floating-menu>
</ng-template>

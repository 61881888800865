import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-action-toastr',
  templateUrl: './action-toastr.component.html',
  styleUrls: ['./action-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 1,
      })),
      transition('inactive <=> active', animate('500ms ease-out', keyframes([
        style({
          transform: 'translateX(340px)',
          offset:0,
          opacity: 0,
        }),
        style({
          offset:.7,
          opacity: 1,
          transform: 'translateX(-20px)'
        }),
        style({
          offset: 1,
          transform: 'translateX(0)',
        })
      ]))),
      transition('active => removed', animate('500ms ease-in', keyframes([
        style({
          transform: 'translateX(-20px)',
          opacity: 1,
          offset: .2
        }),
        style({
          opacity:0,
          transform: 'translateX(340px)',
          offset: 1
        })
      ])))
    ]),
  ],
  preserveWhitespaces: false,
})
export class ActionToastrComponent extends Toast {

  _bgColor   : string = '#D7DEF2';
  _txTColor  : string = '#2D4786';
  _txLColor  : string = '#5E7FCD';
  _mediumSize: boolean = false;

  constructor(
    protected override toastrService: ToastrService,
    public    override toastPackage : ToastPackage,
  ) {
    super( toastrService, toastPackage );
    const payload = this.toastPackage.config.payload;
    if ( payload ) {
      this._bgColor    = payload.background_color ?? this._bgColor;
      this._txTColor   = payload.title_color ?? this._txTColor;
      this._txLColor   = payload.label_color ?? this._txLColor;
      this._mediumSize = payload.medium_size ?? this._mediumSize;
    }
  }

  onAction() {
    this.toastPackage.triggerAction();
    this.toastrService.clear( this.toastrService.currentlyActive );
    return true;
  }

}

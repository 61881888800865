import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { CatalogueService } from '../../services/catalogue/catalogue.service';
import { AuthService } from '../../services/firebase/auth.service';
import { log } from 'echarts/types/src/util/log';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  itemSubscription : Subscription | undefined;
  routeSubscription: Subscription | undefined;

  items: Menu[] = [];

  showCatalogueOption: boolean = false;
  valorResponse:any

  constructor(
    private navService      : NavService,
    private router          : Router,
    public  catalogueService: CatalogueService,
    private authService     :AuthService,
  ) { }


  /* LIFE CYCLE */
  ngOnInit(): void {
    this.itemSubscription = this.navService.items.subscribe((value) => {
      this.items = value;
    });

    this.routeSubscription = this.router.events.subscribe(() => {
      this.handleVisibilityOptions();
    });
  }

  ngAfterViewInit(): void {
    this.setListeners();
  }

  ngOnDestroy(): void {
    this.itemSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();

    document.removeEventListener('click', this.handleAddDisplay );
  }

  /* METHODS */
  setListeners(): void {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    menuIcon?.addEventListener('click', () => {
      menuElem?.classList.toggle('d-none');
    });

    document.addEventListener('click', this.handleAddDisplay );
  }

  handleAddDisplay( event: any ): any {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    const isClickInsideDropdown = menuElem?.contains( event.target );
    const isProfileClicked      = menuIcon?.contains( event.target );

    if ( !isClickInsideDropdown && !isProfileClicked ) {
      menuElem?.classList.add('d-none');
    }
  }

  moveToLink( link: string ): any {
    var menuElem = document.querySelector('#floating_menu_simple_header');
    menuElem?.classList.add('d-none');

    this.router.navigate([ link ]);
  }

  handleVisibilityOptions(): void {
    this.showCatalogueOption = false;

    const path = this.router.url
      .split('/')
      .filter( (a) => a.length > 0 )
      [0];

    if ( path === 'catalogue' ) {
      this.showCatalogueOption = true;
    }
  }
}

<div class="floating-menu">
  <div class="menu-header">
    <h4 class="heading-4-bold text-secondary-dark">
      Grupo PDC
    </h4>

    <button
      class="btn btn-link-primary"
      aria-hidden="true"
      >
      Cambiar
    </button>
  </div>
  <div class="menu-content">
    <ngb-accordion class="nav-accordion">
      <ng-container *ngFor="let item of items; let i = index">
        <ngb-panel title="{{ item.headTitle }}" cardClass="">
          <ng-template ngbPanelContent>
            <ng-container
              *ngTemplateOutlet="
                GroupContainer;
                context: {
                  $implicit: item
                }
              "
            >
            </ng-container>
          </ng-template>
        </ngb-panel>
      </ng-container>
    </ngb-accordion>
  </div>
</div>

<ng-template #GroupContainer let-item>
  <ul>
    <li class="py-0" *ngFor="let section of item.children">
      <p class="group-item">
        <span class="material-symbols-outlined group-item_icon">{{ section.icon }}</span><span class="tx-body-1 tx-neutral-90 ms-2">{{ section.title }}</span>
      </p>
      <ul>
        <li *ngFor="let subSection of section.children">
          <a class="group-item" [routerLink]="subSection.path">
            <span class="material-symbols-outlined group-item_icon">{{ subSection.icon }}</span>
            <span class="tx-body-1 tx-neutral-90 ms-2">{{ subSection.title }}</span>
          </a>
          <ul>
            <li *ngFor="let subLink of subSection.children">
              <a class="group-item" [routerLink]="subLink.path">
                <span class="tx-body-2 tx-neutral-90">{{ subLink.title }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</ng-template>
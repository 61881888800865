import { Component, Input } from '@angular/core';
import { Menu } from '../../services/nav.service';

@Component({
  selector: 'app-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent {

  @Input() items: Menu[] = [];
}
